import React, { Component } from "react";
import ActionButton from "./ActionButton";

class Like extends Component {
  style = {
    likeContainer: {
      display: "inline",
      margin: "0 0 0 20px",
      position: "relative",
      top: "-7px",
      userSelect: "none",
    },
    likeCount: {
      display: "inline",
      position: "relative",
      top: "6px",
      fontWeight: "700",
    },
  };

  render() {
    const { location, incrementLikes } = this.props;

    return (
      <div style={this.style.likeContainer} className="likes-container">
        <ActionButton label="Like" onClick={() => incrementLikes(location)} />
        <div style={this.style.likeCount}>
          {location.attributes.LIKES ?? "0"}
        </div>
      </div>
    );
  }
}

export default Like;
