import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup, Pane } from "react-leaflet";
import { Icon } from "leaflet";
import Loading from "./Loading";

class LeafletNode extends Component {
  state = {};

  // Get average x and y of all coords to center map on the "middle" of all supplied locations
  getAverageLocation(coordinatesList) {
    const { markers } = this.props;
    let averagedCoords = { x: 0, y: 0 };
    let averageX = 0;
    let averageY = 0;

    if (markers !== undefined) {
      const numberOfLocations = markers.length;
      if (markers.length > 1) {
        averageX =
          markers.reduce((acc, current) => acc + current.geometry.x, 0) /
          numberOfLocations;
        averageY =
          markers.reduce((acc, current) => acc + current.geometry.y, 0) /
          numberOfLocations;
      } else {
        averageY = markers.geometry.y;
        averageX = markers.geometry.x;
      }

      averagedCoords = {
        y: averageY,
        x: averageX,
      };
    }

    return Object.values(averagedCoords);
  }

  render() {
    let { markers } = this.props;
    const { highlightedLocation } = this.props;
    const averagedCoords = this.getAverageLocation(markers);

    const myMarker = new Icon({
      iconUrl: "images/marker.png",
      iconSize: [15, 25],
      iconOpacity: 0.5,
    });

    const myHighlightedMarker = new Icon({
      iconUrl: "images/highlighted-marker.png",
      iconSize: [20, 33],
      iconOpacity: 0.5,
    });

    if (markers !== undefined) {
      if (markers.length !== undefined) {
        if (highlightedLocation !== null) {
          return (
            <Map center={averagedCoords} zoom={11}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Pane style={{ zIndex: "600" }}>
                <Marker
                  icon={myHighlightedMarker}
                  key={highlightedLocation.attributes.OBJECTID}
                  position={[
                    highlightedLocation.geometry.y,
                    highlightedLocation.geometry.x,
                  ]}
                ></Marker>
              </Pane>
              <Pane>
                {markers.map((m) => (
                  <Marker
                    icon={myMarker}
                    key={m.attributes.OBJECTID}
                    position={[m.geometry.y, m.geometry.x]}
                  >
                    <Popup>
                      {m.attributes.NAAM}
                      <br />
                      {m.attributes.VERKOOP}
                    </Popup>
                  </Marker>
                ))}
              </Pane>
            </Map>
          );
        } else {
          return (
            <Map center={averagedCoords} zoom={11}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              {markers.map((m) => (
                <Marker
                  icon={myMarker}
                  key={m.attributes.OBJECTID}
                  position={[m.geometry.y, m.geometry.x]}
                >
                  <Popup>
                    {m.attributes.NAAM}
                    <br />
                    {m.attributes.VERKOOP}
                  </Popup>
                </Marker>
              ))}
            </Map>
          );
        }
      } else {
        return (
          <Map center={averagedCoords} zoom={16}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            <Marker
              icon={myMarker}
              key={markers.attributes.OBJECTID}
              position={[markers.geometry.y, markers.geometry.x]}
            >
              <Popup>
                <br />
              </Popup>
            </Marker>
          </Map>
        );
      }
    } else {
      return <Loading />;
    }
  }
}

export default LeafletNode;
