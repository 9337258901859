import React, { Component } from "react";

class ApiLoadingError extends Component {
  state = {};

  style = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 4em - 2px)",
      justifyContent: "center",
      alignItems: "center",
    },
    header: { margin: "15px" },
  };
  render() {
    return (
      <div style={this.style.container}>
        <h1 style={this.style.header}>Something went horribly wrong</h1>
        <p>I'm sorry</p>
      </div>
    );
  }
}

export default ApiLoadingError;
