import React, { Component } from "react";
import Header from "./Header";
import Main from "./Main";
import "./App.css";
import ApiLoadingError from "./ApiLoadingError";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      data: [],
      staticData: false,
    };
  }

  componentDidMount() {
    const url = new URL(
      "https://geodata.antwerpen.be/arcgissql/rest/services/P_Portal/portal_publiek5/MapServer/571/query"
    );
    url.search = new URLSearchParams({
      where: "1=1",
      outFields: "*",
      outSR: "4326",
      f: "json",
    });

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // certain errors in Antwerp's api return a data object containing a true "error" key.
        // For the JS fetch API this is considered a succesfull call
        // You need to check for an error:false key and handle that
        //
        // Actual succes: call succeeded.
        // Fake succes: call succeeded, but returned data.error -> load static data
        // Actual fail: call failed -> load static data
        if (!data.error) {
          console.log("API call: actual succes (data can be used)", data);
          this.setState({ data});
        } else {
          console.log(data);
          console.log("API call: fake succes (data contains error key)", data);
          alert(
            "Error loading API data! ☹️\nApi error: " +
              data.error.message +
              "\n\nAttempting to load static data..."
          );
          this.loadStaticJsonData();
        }
      })
      .catch((err) => {
        console.log("API call: actual fail (no data returned)", err);
        alert(
          "Error loading API data! ☹️\nApi error: " +
            err +
            "\n\nAttempting to load static data..."
        );
        this.loadStaticJsonData();
      });
  }

  loadStaticJsonData() {
    const path = "./data/locaties_akaart.json";
    fetch(path)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data, staticData: true });
      })
      .catch((err) => alert("Error loading static data! ☹️ \nError: ", err));
  }

  handleIncrementLikes = (location) => {
    const { features } = this.state.data;
    let copiedLocationsFromState = [...features];
    const index = copiedLocationsFromState.indexOf(location);

    if ("LIKES" in copiedLocationsFromState[index].attributes) {
      copiedLocationsFromState[index].attributes.LIKES++;
    } else {
      copiedLocationsFromState[index].attributes.LIKES = 1;
    }

    this.setState({ features: copiedLocationsFromState });
  };

  handleAddComment = (location, comment) => {
    const { features } = this.state.data;
    let copiedLocationsFromState = [...features];
    const index = copiedLocationsFromState.indexOf(location);
    const newEntry = {
      comment: comment,
      date: new Date(),
    };

    if (!Array.isArray(copiedLocationsFromState[index].attributes.COMMENTS)) {
      copiedLocationsFromState[index].attributes.COMMENTS = [];
    }
    copiedLocationsFromState[index].attributes.COMMENTS.push(newEntry);

    this.setState({ features: copiedLocationsFromState });
  };

  render() {
    return (
      <React.Fragment>
        <Header staticData={this.state.staticData} />
        {!this.state.data.error ? (
          <Main
            data={this.state.data}
            incrementLikes={this.handleIncrementLikes}
            addComment={this.handleAddComment}
          />
        ) : (
          <ApiLoadingError />
        )}
      </React.Fragment>
    );
  }
}

export default App;
