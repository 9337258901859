import React, { Component } from "react";
import "./Comment.css";

class Comment extends Component {
  render() {
    const { location, addComment } = this.props;
    if (location.attributes.COMMENTS !== undefined) {
      return (
        <div className="main-comments-container">
          <div className="comment-container">
            <h2 className="comment-title">Commentaren</h2>
            {location.attributes.COMMENTS.map((m) => (
              <div key={location.attributes.COMMENTS.indexOf(m)}>
                <p className="comment">{m.comment}</p>
                <p className="date">
                  Geplaatst op {m.date.toLocaleDateString("nl-BE")}
                </p>
                <hr style={{ margin: "0 8px 5px 0" }} />
              </div>
            ))}
          </div>
          <div className="comment-input-container">
            <form>
              <textarea
                name="comment"
                placeholder="Typ hier je commentaar (max 2000 charakters)"
              ></textarea>
              <input
                type="submit"
                value="Bewaar"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    document.getElementsByName("comment")[0].value !== "" &&
                    document.getElementsByName("comment")[0].value.length < 2000
                  ) {
                    addComment(
                      location,
                      document.getElementsByName("comment")[0].value.trim()
                    );
                    document.getElementsByName("comment")[0].value = "";
                  }
                }}
              ></input>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-comments-container">
          <div className="comment-container">
            <h2 className="comment-title">Commentaren</h2>
            <p className="comment">Geen commentaren gevonden</p>
          </div>
          <div className="comment-input-container">
            <form>
              <textarea
                name="comment"
                placeholder="Typ hier je commentaar (max 2000 charakters)"
              ></textarea>
              <input
                type="submit"
                value="Bewaar"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    document.getElementsByName("comment")[0].value !== "" &&
                    document.getElementsByName("comment")[0].value.length < 2000
                  ) {
                    addComment(
                      location,
                      document.getElementsByName("comment")[0].value.trim()
                    );
                    document.getElementsByName("comment")[0].value = "";
                  }
                }}
              ></input>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default Comment;
