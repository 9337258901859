import React, { Component } from "react";
import LocationOverview from "./LocationOverview";
import LeafletNode from "./LeafletNode";
import ApiLoadingError from "./ApiLoadingError";
import "leaflet/dist/leaflet.css";
import LocationDetail from "./LocationDetail";
import "./Main.css";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overview: true,
      location: null,
      focusedLocation: null,
    };
  }

  handleShowDetail = (location) => {
    this.setState({ overview: false, location: location });
  };

  handleShowOverview = () => {
    this.setState({ overview: true, location: null });
  };

  handleGetFocusedLocation = (location) => {
    this.setState({ focusedLocation: location });
  };

  render() {
    const { data, error, incrementLikes, addComment } = this.props;

    if (!error) {
      if (this.state.overview) {
        return (
          <div className="main-page-container">
            <LocationOverview
              locations={data.features}
              showDetail={this.handleShowDetail}
              getFocusedLocation={this.handleGetFocusedLocation}
            />
            <LeafletNode
              markers={data.features}
              highlightedLocation={this.state.focusedLocation}
            />
          </div>
        );
      } else {
        return (
          <LocationDetail
            location={this.state.location}
            showOverview={() => this.handleShowOverview()}
            incrementLikes={incrementLikes}
            addComment={addComment}
          />
        );
      }
    } else {
      return <ApiLoadingError />;
    }
  }
}

export default Main;
