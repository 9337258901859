import React, { Component } from "react";
import "./ActionButton.css";

class ActionButton extends Component {
  render() {
    return (
      <div className="action-button" onClick={this.props.onClick}>
        {typeof this.props.label == undefined ? "Click me" : this.props.label}
      </div>
    );
  }
}

export default ActionButton;
