import React, { Component } from "react";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <h1>
          ScanMyCard{" "}
          <span className="icon" role="img" aria-label="Creditcard icon">
            💳
          </span>
          <span className="data-origin-label">
            (with{" "}
            <span className={this.props.staticData ? "" : "line-through"}>
              static
            </span>
            {"/"}
            <span className={this.props.staticData ? "line-through" : ""}>
              API
            </span>{" "}
            data)
          </span>
        </h1>
      </div>
    );
  }
}

export default Header;
