import React, { Component } from "react";
import LocationCard from "./LocationCard";
import Loading from "./Loading";
import "./LocationOverview.css";

class LocationOverview extends Component {
  render() {
    const { locations, showDetail, getFocusedLocation } = this.props;

    if (locations !== undefined) {
      return (
        <div className="parent-container">
          <div className="title-container">
            <h2>A-kaart zuilen</h2>
          </div>
          <div className="card-container">
            {locations.map((location) => (
              <LocationCard
                key={location.attributes.OBJECTID}
                location={location}
                onClick={() => showDetail(location)}
                getFocusedLocation={getFocusedLocation}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default LocationOverview;
