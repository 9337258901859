import React, { Component } from "react";
import ActionButton from "./ActionButton";
import "./LocationDetail.css";
import LeafletNode from "./LeafletNode";
import Like from "./Like";
import Comment from "./Comment";

class LocationDetail extends Component {
  render() {
    const { location, showOverview, incrementLikes, addComment } = this.props;

    return (
      <div className="main-container">
        <div className="image-container">
          <img
            className="image"
            src={location.attributes.FOTO1 ?? "images/building-placeholder.png"}
            alt={location.attributes.NAAM}
          />
        </div>
        <div className="detail-container">
          <h2 className="inline-block">{location.attributes.NAAM}</h2>
          <Like
            className="inline-block"
            location={location}
            incrementLikes={incrementLikes}
          />
          <h3>{location.attributes.DISTRICT}</h3>
          <p className="detail-content">
            {location.attributes.STRAAT} {location.attributes.HUISNR}
          </p>
          <p className="detail-content">
            {location.attributes.POSTCODE} {location.attributes.DISTRICT}
          </p>
          <p className="detail-content">
            <a
              href={location.attributes.LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {location.attributes.LINK}
            </a>
          </p>
          <p className="detail-content">{location.attributes.VERKOOP}</p>
        </div>
        <div className="map-container">
          <LeafletNode markers={location} />
          <p className="map-text">
            Coordinaten: {location.geometry.y.toPrecision(6)}° N,{" "}
            {location.geometry.x.toPrecision(6)}° E
          </p>
        </div>
        <div className="button-container">
          <ActionButton onClick={() => showOverview()} label="Overzicht" />
        </div>
        <div className="comment-area-container">
          <Comment location={location} addComment={addComment} />
        </div>
      </div>
    );
  }
}

export default LocationDetail;
