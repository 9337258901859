import React, { Component } from "react";
import "./LocationCard.css";

class LocationCard extends Component {
  render() {
    const { location, onClick, getFocusedLocation } = this.props;

    return (
      <div
        className="card"
        onClick={() => onClick(location)}
        onMouseOver={() => {
          getFocusedLocation(location);
        }}
      >
        <img
          src={location.attributes.FOTO1 ?? "images/building-placeholder.png"}
          alt={location.attributes.NAAM}
        />
        <div className="card-titles center">
          <h3 title={location.attributes.NAAM}>{location.attributes.NAAM}</h3>
          <h4>{location.attributes.DISTRICT}</h4>
        </div>
        <div className="card-content">
          <div className="likes center">
            <h3>{location.attributes.LIKES ?? "0"}</h3>
            <h5>LIKES</h5>
          </div>
          <div className="comments center">
            <h3>
              {"COMMENTS" in location.attributes
                ? location.attributes.COMMENTS.length
                : "0"}
            </h3>
            <h5>COMMENTS</h5>
          </div>
        </div>
        <div className="card-button center">BEKIJK DETAILS</div>
      </div>
    );
  }
}

export default LocationCard;
